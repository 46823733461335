import { BrowserAnalyticsClient } from './client.js';

declare global {
  interface Window {
    remento: {
      Analytics: typeof BrowserAnalyticsClient;
    };
  }
}

if (window.remento == null) {
  window.remento = {
    Analytics: BrowserAnalyticsClient,
  };
} else {
  window.remento.Analytics = BrowserAnalyticsClient;
}
