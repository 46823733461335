import logger from '@remento-infrastructure/common/logger';

import { AnalyticsContext } from '../../@types.js';

import { PageExitActivityEvent, PageInitActivityEvent } from './activity.types.js';

export function registerActivityHandler(context: AnalyticsContext) {
  context.router.registerHandler('activity-handler', (event, payload) => {
    switch (event) {
      case PageExitActivityEvent: {
        logger.debug(`[HANDLER - ACTIVITY] Received event ${PageExitActivityEvent}`, payload);
        context.client.track('page.exit', { ...payload.data, ...payload.properties });
        return true;
      }
      case PageInitActivityEvent: {
        logger.debug(`[HANDLER - ACTIVITY] Received event ${PageInitActivityEvent}`, payload);
        context.client.page(`${payload.properties.pageId}` ?? 'unknown', { ...payload.data, ...payload.properties });
        return true;
      }
    }
    return false;
  });
}
