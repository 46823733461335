export class PersistentStorage {
  constructor(private prefix: string) {}

  private buildKey(key: string) {
    return `${this.prefix}:${key}`;
  }

  setItem(key: string, value: unknown, ttl = -1) {
    localStorage.setItem(
      this.buildKey(key),
      JSON.stringify({
        value,
        expires: ttl > 0 ? Date.now() + ttl : -1,
      }),
    );
  }

  getItem<T>(key: string): T | null {
    const itemEntryRaw = localStorage.getItem(this.buildKey(key));
    if (itemEntryRaw == null) {
      return null;
    }
    const itemEntry = JSON.parse(itemEntryRaw);
    if (0 < itemEntry.expires && itemEntry.expires <= Date.now()) {
      return null;
    }
    return itemEntry.value;
  }

  isItemExpired(key: string): boolean {
    const itemEntryRaw = localStorage.getItem(this.buildKey(key));
    if (itemEntryRaw == null) {
      return true;
    }
    const itemEntry = JSON.parse(itemEntryRaw);
    return 0 < itemEntry.expires && itemEntry.expires <= Date.now();
  }
}
