import logger from '@remento-infrastructure/common/logger';

import { AnalyticsContext } from '../../@types.js';
import { Payload } from '../../router.js';

import { ClickEvent, ClickPayload } from './click.types.js';

export function registerClickHandler(context: AnalyticsContext) {
  context.router.registerHandler('click-handler', (event, payload) => {
    switch (event) {
      case ClickEvent: {
        const clickPayload = payload as Payload<ClickPayload>;
        logger.debug(`[HANDLER - CLICK] Received event ${ClickEvent}`, clickPayload);
        context.client.track(clickPayload.data.event, { ...payload.properties });
        return true;
      }
    }
    return false;
  });
}
