import logger from '@remento-infrastructure/common/logger';
import { v4 as uuid } from 'uuid';

import { PersistentStorage } from '../../../../common/src/storage.js';
import { AnalyticsContext } from '../../@types.js';
import { Payload } from '../../router.js';

import { PageExitActivityEvent, PageExitActivityPayload, PageInitActivityEvent } from './activity.types.js';
import { PersistentActivityRepository } from './activity-repository.js';

const ACTIVITY_PLUGIN_ID = 'activity-plugin';

export class ActivityObserver {
  private repository: PersistentActivityRepository;
  public sessionId: string;
  public sessionStartOn: number;
  public pageDuration: number;
  public pageActiveStartTime: number;

  constructor(private context: AnalyticsContext, sessionTTL: number, private pageTTL: number) {
    this.repository = new PersistentActivityRepository(new PersistentStorage(context.config.clientSource), sessionTTL);
    this.sessionId = this.repository.getSessionId();
    this.sessionStartOn = this.repository.getSessionStartOn();
    this.pageDuration = 0;
    this.pageActiveStartTime = Date.now();
  }

  run() {
    const router = this.context.router;
    const repository = this.repository;

    let active = true;
    let exited = false;
    let exitTimeout: number | null = null;

    const synchronizeSessionDuration = () => {
      repository.saveSession(this.sessionId, this.sessionStartOn);
    };

    setInterval(() => {
      if (!active) return;
      synchronizeSessionDuration();
    }, 1000);

    window.addEventListener('beforeunload', () => {
      if (exited) return;
      const payload = {
        data: { cause: 'unload' },
        properties: {},
      } as Payload<PageExitActivityPayload>;
      logger.debug(`[OBSERVER - ACTIVITY] Publishing event ${PageExitActivityEvent}`, payload);
      router.publish(PageExitActivityEvent, payload);
      synchronizeSessionDuration();
    });
    window.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        if (exitTimeout != null) clearTimeout(exitTimeout);
        active = true;
        this.pageActiveStartTime = Date.now();
        if (repository.isSessionExpired()) {
          // Reset all duration
          this.pageDuration = 0;
          this.sessionId = uuid();
          this.sessionStartOn = Date.now();
        } else {
          // Recover the session duration from local storage (which may have been updated by a different tab)
          this.sessionId = repository.getSessionId();
          this.sessionStartOn = repository.getSessionStartOn();
        }
        // Grab the latest session ID or generate a new one if necessary

        if (exited) {
          // Reset the page duration
          this.pageDuration = 0;
          // The exit timer fired due to inactivity, act as if they were loading the page again
          const payload = { data: null, properties: {} };
          logger.debug(`[OBSERVER - ACTIVITY] Publishing event ${PageInitActivityEvent}`, payload);
          router.publish(PageInitActivityEvent, payload);
          exited = false;
        }

        synchronizeSessionDuration();
      } else {
        synchronizeSessionDuration();
        active = false;
        this.pageDuration += Date.now() - this.pageActiveStartTime;
        this.pageActiveStartTime = Date.now();

        exitTimeout = setTimeout(() => {
          const payload = {
            data: { cause: 'inactive' },
            properties: {},
          } as Payload<PageExitActivityPayload>;
          // Send the same event as if the user had unloaded the page
          logger.debug(`[OBSERVER - ACTIVITY] Publishing event ${PageExitActivityEvent}`, payload);
          router.publish(PageExitActivityEvent, payload);
          exited = true;
        }, this.pageTTL);
      }
    });
    setTimeout(() => router.publish(PageInitActivityEvent, { data: null, properties: {} }), 0);
  }
}

export function registerActivityPlugin(
  context: AnalyticsContext,
  sessionTTL: number,
  pageTTL: number,
): ActivityObserver {
  if (context.router.hasPlugin(ACTIVITY_PLUGIN_ID)) {
    console.warn('The activity tracker module is already registered');
    return context.router.getPlugin(ACTIVITY_PLUGIN_ID)!;
  }
  const activityPlugin = new ActivityObserver(context, sessionTTL, pageTTL);
  context.router.registerPlugin(ACTIVITY_PLUGIN_ID, activityPlugin);
  activityPlugin.run();
  return activityPlugin;
}
