import { Router } from '../router.js';
import { ActivityObserver } from '../sources/activity/activity-plugin.js';

export function registerActivityTransform(router: Router) {
  const activity: ActivityObserver | null = router.getPlugin('activity-plugin');
  if (activity == null) {
    console.warn('Cannot register the activity transform if the activity plugin is not registered');
    return;
  }
  router.registerTransform('activity-transform', (event, payload) => {
    return {
      ...payload,
      properties: {
        pageDuration: (activity.pageDuration + Date.now() - activity.pageActiveStartTime) / 1000,
        sessionId: activity.sessionId,
        sessionDuration: (Date.now() - activity.sessionStartOn) / 1000,
        sessionActiveDuration: (Date.now() - activity.sessionStartOn) / 1000,
        ...payload.properties,
      },
    };
  });
}
