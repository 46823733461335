import logger from '@remento-infrastructure/common/logger';

import { Debugger } from '../../debugger';
import { Payload, Router } from '../../router.js';

import { ClickEvent, ClickPayload } from './click.types.js';

export const ACTION_PROP_PREFIX = `data-analytics-event-prop-`;

function extractClickPayload($element: HTMLElement): Payload<ClickPayload> | null {
  const analyticsEvent = $element.attributes.getNamedItem('data-analytics-event')?.value ?? null;
  if (analyticsEvent == null || analyticsEvent.length == 0) {
    console.warn('[OBSERVER - CLICK] Failed to parse event', $element);
    return null;
  }
  const analyticsEventProps: Record<string, string> = {};
  for (let i = 0; i < $element.attributes.length; i++) {
    const attribute = $element.attributes.item(i);
    const attributeName = attribute?.name;
    if (attributeName == null || !attributeName.startsWith(ACTION_PROP_PREFIX)) {
      continue;
    }
    const attributeValue = attribute?.value;
    if (attributeValue == null || attributeValue.length == 0) {
      console.warn('[OBSERVER - CLICK] Failed to parse event properties', $element);
      return null;
    }
    analyticsEventProps[attributeName.substring(ACTION_PROP_PREFIX.length)] = attributeValue;
  }
  let link = $element.getAttribute('href');
  if (link != null) {
    try {
      if (link.startsWith('clkn') || link.startsWith('clkg')) {
        // UNBOUNCE
        link = link.substring(5);
        if (link.startsWith('https/')) {
          link = 'https://' + link.substring(6);
        } else if (link.startsWith('http/')) {
          link = 'http://' + link.substring(5);
        }
        const url = new URL(link);
        analyticsEventProps.link = `${url.origin}${url.pathname}`;
      } else {
        // EVERYTHING ELSE
        const url = new URL(link);
        analyticsEventProps.link = `${url.origin}${url.pathname}`;
      }
    } catch (err) {
      logger.warn(`Failed to parse link`, err);
    }
  }
  return {
    data: { event: analyticsEvent },
    properties: analyticsEventProps,
  };
}

export function registerClickElementObserver(router: Router, $element: HTMLElement) {
  if ($element.dataset.actionRegistered != null) {
    return console.warn('[OBSERVER - CLICK] Clicks are already tracked for ', $element);
  }
  $element.dataset.actionRegistered = 'yes';
  $element.addEventListener('click', () => {
    const payload = extractClickPayload($element);
    if (payload == null) {
      return;
    }
    logger.debug(`[OBSERVER - CLICK] Publishing event ${ClickEvent}`, payload);
    router.publish(ClickEvent, payload);
  });
}

export function registerClickObserver(router: Router, debug?: Debugger) {
  const $elements = document.querySelectorAll('[data-analytics-event]');
  for (let i = 0; i < $elements.length; i++) {
    registerClickElementObserver(router, $elements.item(i) as HTMLElement);
  }
  debug?.registerTooltips('click-observer', () => ({
    elements: Array.from($elements as NodeListOf<HTMLElement>),
    getEvent: ($element) => {
      const payload = extractClickPayload($element);
      return { type: payload?.data.event ?? null, payload };
    },
  }));
}
