import { v4 as uuid } from 'uuid';

import { PersistentStorage } from '../../../../common/src/storage.js';

export class PersistentActivityRepository {
  constructor(private storage: PersistentStorage, private ttl: number) {}

  getSessionStartOn(): number {
    return this.storage.getItem('analytics:session:start-on') ?? Date.now();
  }

  getSessionId(): string {
    return this.storage.getItem('analytics:session:id') ?? uuid();
  }

  isSessionExpired(): boolean {
    return this.storage.isItemExpired('analytics:session:id') ?? true;
  }

  saveSession(sessionId: string, sessionStartOn: number) {
    this.storage.setItem('analytics:session:id', sessionId, this.ttl);
    this.storage.setItem('analytics:session:start-on', sessionStartOn, this.ttl);
  }
}
